<template>
  <div class="container">
    <div class="side-block">
      <small-block
        v-for="item in blockList"
        :key="item.id"
        :item="item"
        :class="[
          item.title === '新风系统' ? 'border-block' : '',
          'small-block',
        ]"
        :bgColor="item.title === '新风系统'"
        @click.native="changeBlock(item.title)"
      ></small-block>
    </div>
    <div class="table-container">
      <table-tab :tabs="tabs" @changeTab="changeTab"></table-tab>
      <div class="table">
        <div class="table-info">
          <div class="icon-container">
            <i class="iconfont icon-weixiu1 icon-position"></i>
          </div>
          <div class="text-container">
            <span
              >当前有
              <span style="color: #e73838">{{ $store.state.airState }}</span>
              台新风设备需维护</span
            >
            <span>客服电话: 0512-65765088</span>
          </div>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          fit
          height="480"
          v-loading="requestLoading"
        >
          <el-table-column prop="maintain_state" label="维护状态">
            <template slot-scope="scope">
              <span>{{ status(scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="className" label="所属场景">
            <template slot-scope="scope">
              <span>{{ scope.row.scene }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="device_state" label="设备状态">
            <template slot-scope="scope">
              <div class="device_state">
                <span v-if="scope.row.activeStatus === 1">已开机</span>
                <span v-else-if="scope.row.activeStatus === 0">已关机</span>
                <span v-else>已离线</span>
                <!--                <el-switch-->
                <!--                  :value="scope.row.activeStatus === 1"-->
                <!--                  disabled-->
                <!--                  active-color="#01cfa5"-->
                <!--                ></el-switch>-->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="device_type" label="设备型号">
            <template slot-scope="scope">
              <span>{{ scope.row.type }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提交时间">
            <template slot-scope="scope">
              <span v-if="scope.row.commit_hour">{{
                scope.row.commit_hour
              }}</span>
              <span v-else class="post-date" @click="maintainRequest(scope.row)"
                >提交申请</span
              >
            </template>
          </el-table-column>
          <el-table-column label="处理时间">
            <template slot-scope="scope">
              <span v-if="scope.row.handling_time">{{
                scope.row.handling_time
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="完成时间">
            <template slot-scope="scope">
              <span v-if="scope.row.finish_time">{{
                scope.row.finish_time
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SmallBlock } from "../../components/widget";
import { TableTab } from "../../components/layout";
export default {
  name: "Maintain",
  components: {
    SmallBlock,
    TableTab,
  },
  data() {
    return {
      blockList: [
        {
          id: 1,
          title: "新风系统",
          num: 0,
          extra: "",
        },
        {
          id: 2,
          title: "初效滤网",
          num: 0,
          extra: "",
        },
        {
          id: 3,
          title: "中效滤网",
          num: 0,
          extra: "",
        },
        {
          id: 4,
          title: "高效滤网",
          num: 0,
        },
      ],
      tabs: [
        {
          id: 1,
          title: "所有设备",
        },
        {
          id: 2,
          title: "未申请",
        },
        {
          id: 3,
          title: "申请中",
        },
        {
          id: 4,
          title: "已处理",
        },
        {
          id: 5,
          title: "已完成",
        },
      ],
      resultData: [
        {
          id: 1,
          maintain_state: "未申请",
          className: "一年级1班",
          device_state: "offline",
          device_type: "JD010023",
        },
        {
          id: 2,
          maintain_state: "申请中",
          className: "一年级2班",
          device_state: "online",
          device_type: "JD010023",
          post_date: "2021-3-22 12:30:00",
        },
        {
          id: 3,
          maintain_state: "已处理",
          className: "一年级3班",
          device_state: "offline",
          device_type: "JD010023",
          post_date: "2021-3-22 12:30:00",
          deal_date: "2021-3-22 12:30:00",
        },
        {
          id: 4,
          maintain_state: "已完成",
          className: "一年级4班",
          device_state: "online",
          device_type: "JD010023",
          post_date: "2021-3-22 12:30:00",
          deal_date: "2021-3-22 12:30:00",
          complete_date: "2021-3-22 12:30:00",
        },
        {
          id: 5,
          maintain_state: "已完成",
          className: "一年级4班",
          device_state: "online",
          device_type: "JD010023",
          post_date: "2021-3-22 12:30:00",
          deal_date: "2021-3-22 12:30:00",
          complete_date: "2021-3-22 12:30:00",
        },
        {
          id: 6,
          maintain_state: "已完成",
          className: "一年级4班",
          device_state: "online",
          device_type: "JD010023",
          post_date: "2021-3-22 12:30:00",
          deal_date: "2021-3-22 12:30:00",
          complete_date: "2021-3-22 12:30:00",
        },
        {
          id: 7,
          maintain_state: "已完成",
          className: "一年级4班",
          device_state: "online",
          device_type: "JD010023",
          post_date: "2021-3-22 12:30:00",
          deal_date: "2021-3-22 12:30:00",
          complete_date: "2021-3-22 12:30:00",
        },
        {
          id: 8,
          maintain_state: "已完成",
          className: "一年级4班",
          device_state: "online",
          device_type: "JD010023",
          post_date: "2021-3-22 12:30:00",
          deal_date: "2021-3-22 12:30:00",
          complete_date: "2021-3-22 12:30:00",
        },
      ],
      tableData: [],
      requestLoading: false,
    };
  },
  methods: {
    //提交申请
    maintainRequest(row) {
      this.requestLoading = true;
      this.$http
        .postMaintainRequest(row.deviceId, row.status)
        .then((res) => {
          console.log(res);
          this.$store.dispatch("getMaintainedAirList").then(() => {
            this.resultData = this.$store.state.maintainedAirList;
            console.log(this.resultData, "air");
            this.tableData = [...this.resultData];
            this.requestLoading = false;
          });
        })
        .catch(() => {
          this.requestLoading = false;
        });
    },
    changeBlock(title) {
      if (title !== "新风系统") {
        this.$router.push({
          path: "/detail/filter",
          query: { title },
        });
      }
    },
    changeTab(data) {
      if (data.id === 1) {
        this.tableData = [...this.resultData];
      } else if (data.id === 2) {
        this.tableData = this.resultData.filter((item) => {
          return item.status === 1;
        });
      } else if (data.id === 3) {
        this.tableData = this.resultData.filter((item) => {
          return item.status === 2;
        });
      } else if (data.id === 4) {
        this.tableData = this.resultData.filter((item) => {
          return item.status === 3;
        });
      } else if (data.id === 5) {
        this.tableData = this.resultData.filter((item) => {
          return item.status === 4;
        });
      }
    },
    status(num) {
      switch (num) {
        case 1:
          return "未申请";
        case 2:
          return "已申请";
        case 3:
          return "已处理";
        case 4:
          return "已完成";
      }
    },
  },
  mounted() {
    console.log(this.$store.state.airListRequire, "rq");
    if (!this.$store.state.airListRequire) {
      this.$store.dispatch("getMaintainedAirList").then(() => {
        this.resultData = this.$store.state.maintainedAirList;
        console.log(this.resultData, "air");
        this.tableData = [...this.resultData];
      });
    } else {
      this.resultData = this.$store.state.maintainedAirList;
      this.tableData = [...this.resultData];
    }
    console.log(this.tableData);
    if (this.$store.state.detailRequireConfirm) {
      this.blockList[0].num = this.$store.state.airState;
      this.blockList[1].num = this.$store.state.filterState.primaryFilter;
      this.blockList[2].num = this.$store.state.filterState.mediumFilter;
      this.blockList[3].num = this.$store.state.filterState.highFilter;
    } else {
      this.$store.dispatch("getAirStateAction").then(() => {
        this.blockList[0].num = this.$store.state.airState;
      });
      this.$store.dispatch("getFilterState").then(() => {
        this.blockList[1].num = this.$store.state.filterState.primaryFilter;
        this.blockList[2].num = this.$store.state.filterState.mediumFilter;
        this.blockList[3].num = this.$store.state.filterState.highFilter;
      });
      this.$store.commit("changeDetailRequireConfirm");
    }
  },

  watch: {
    // "$store.state.filterState"() {
    //   this.blockList[1].num = this.$store.state.filterState.primaryFilter;
    //   this.blockList[2].num = this.$store.state.filterState.mediumFilter;
    //   this.blockList[3].num = this.$store.state.filterState.highFilter;
    // },
    // "$store.state.airState"() {
    //   this.blockList[0].num = this.$store.state.airState;
    // },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 0 20px 20px 0;
  display: flex;
  .side-block {
    //width: 300px;
    display: flex;
    flex-direction: column;
    .small-block {
      width: 280px;
      margin-top: 20px;
      height: 100px;
      cursor: pointer;
    }
    .border-block {
      border: 1px solid #ff6c6c;
      cursor: default;
    }
  }
  .table-container {
    width: 100%;
    min-width: 1200px;
    margin-left: 30px;
    .table {
      background-color: #fff;
      padding: 15px 20px 0 20px;
      .table-info {
        height: 70px;
        background-color: #ffeeee;
        border: 1px solid #ffc8c8;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .icon-container {
          width: 50px;
          height: 50px;
          border: 1px solid #ffc8c8;
          border-radius: 50%;
          background-color: #fff;
          margin-left: 20px;
          text-align: center;
          line-height: 50px;
          .icon-position {
            font-size: 30px;
            color: #ff6c6c;
          }
        }
        .text-container {
          margin-left: 15px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          display: flex;
          flex-direction: column;
          //width: 90px;
        }
      }
      ::v-deep.el-table {
        .device_state {
          display: flex;
          align-items: center;
          justify-content: center;
          .el-switch {
            margin-left: 5px;
          }
        }
        .post-date {
          display: inline-block;
          width: 70px;
          height: 22px;
          background-color: #01cfa5;
          border-radius: 6px;
          font-size: 12px;
          color: #fff;
          line-height: 22px;
          text-align: center;
          cursor: pointer;
        }
        &::before {
          bottom: 100000px;
        }
        th > .cell {
          text-align: center;
          color: #333;
        }
        .el-table__row .cell {
          text-align: center;
        }
        .has-gutter > tr {
          th {
            background-color: #eaf8f8 !important;
          }
        }
      }
    }
  }
}
</style>
